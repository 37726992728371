fuel_id<template>
  <div>
    <div>
    <div  v-if="(this.status == 'Cars Info Validation' || this.status == 'Price Proposal'
                || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')">
      <span id="title" class="text-h6">{{  ('Deal Summary') }}</span>
      <table>
        <thead>
          <tr>
            <th>{{  ('Pending action') }}</th>
            <th>{{  ('Rejected') }}</th>
            <th>{{  ('Approved') }}</th>
            <th> {{ ('Total Proposed Price without Tax') }}</th>
            <th>{{  ('Total Proposed Price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ pending_action }}</td>
            <td>{{ rejected }}</td>
            <td>{{ approved }}</td>
            <td>{{ this.total_proposed_price_without_tax }}</td>
            <td>{{ total_proposed_price }}</td>
          </tr>
        </tbody>
      </table>
    </div> 

      <v-card-title>
        <span id="title" class="text-h6">{{  ('Cars') }}</span>
      </v-card-title>
      <div style="margin-right: 40px; margin-left: 43px;display: flex;justify-content: right;"  v-if="this.status !='New'">
        <v-btn  icon color="green" @click="export_auction" v-if="this.status && this.Has_Access('Auction','export')">
       
          <img src="../../assets/excel.png" width="40px" right/>
          
        </v-btn>
      </div>

      <div
        style="display: flex; justify-content: flex-end; margin-bottom: -2rem"
        v-if="this.status == 'Price Proposal'"
      >


        <div>
          <v-btn
            icon
            color="green"
            @click="setAllThumbsUp"
            style="margin-right: 40px; margin-left: 43px"
            :disabled="this.isresultall"
          >
            <v-icon>mdi-thumb-up</v-icon>
            <div style="font-size: xx-small">all win</div>
          </v-btn>
        </div>
        <div>
          <v-btn
            icon
            color="red"
            @click="setAllThumbsDown"
            :disabled="this.isresultall"
          >
            <v-icon>mdi-thumb-down</v-icon>
            <div style="font-size: xx-small">all Lose</div>
          </v-btn>
        </div>
      </div>

      <template class="my-0 py-0 overflow-y-auto" style="max-height: 75vh">
        <div style="background: white; margin-left: 5px">
          <v-dialog
            v-model="dialog"
            max-width="90%"
            persistent
            style="background: white"
          >
            <v-card class="selection mx-auto grey lighten-5">
              <v-row>
                <v-col>
                  <input
                    v-model="this.header_name"
                    class="text-h6 primary--text mt-4 ml-4"
                    style="width: 200px;"
                    :disabled="true"
                  />
                </v-col>

                <v-col :class="align">
                  <v-btn icon color="primary" @click="close_dialog">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div class="my-0 py-0 overflow-y-auto" style="max-height: 75vh">
                <template @mouseover="style = 'cursor: pointer'">
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="selected_item"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="row_id"
                    class="elevation-1"
                    :items-per-page="itemsPerPage"
                    hide-default-footer 
                     >
                    <template v-slot:item.name="{ item }">
                      <v-simple-checkbox
                        @click="forceUpdateItem"
                        v-model="item.name"
                        :disabled="false"
                        hidden
                      ></v-simple-checkbox>
                    </template>

                    <template v-slot:item.plate="{ item, index }">
                      <v-row class="mt-2">
                        <v-col>
                          <input
                            v-model="item.plate"
                            :ref="index.toString() + '-input1'"
                            dense
                            style="width: 140px"
                            :disabled="true"
                            readonly
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:item.make_year="{ item, index }">
                      <v-col>
                        <input
                          v-model="item.make_year"
                          style="width: 200px;"
                          :disabled="true"
                        />
                      </v-col>
                    </template>

                    <template v-slot:item.odometer="{ item, index }">
                      <v-col>
                        <input
                          v-model="item.odometer"
                          style="width: 200px;"
                          :disabled="true"
                        />
                      </v-col>
                    </template>

                    <template v-slot:item.status="{ item, index }">
                      <v-col>
                        <span
                          :class="index.toString() + '-input10'"
                          >{{ getStatusName(item.status) }}</span
                        >
                      </v-col>
                    </template>

                    <template v-slot:item.inspection="{ item }">
                      <v-col>
                        <v-text-field
                          style="width: 200px;"
                          dense
                          :value="item.inspection"
                          hide-details
                          :disabled="false"
                          @click="print_inspection(item.inspection)"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </template>

                    <template v-slot:item.action_id="{ item }">
                      <v-select
                        style="width: 200px;"
                        dense
                        background-color="white"
                        v-model="item.auction_value"
                        :items="item.auction_options"
                        outlined
                        item-text="name"
                        item-value="id"
                        hide-details
                        :disabled="false"
                        required
                      >
                      </v-select>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row class="mt-2">
                          <v-col v-if="item.name">
                            <div class="primary--text" v-html="item.name"></div>
                          </v-col>
                          <v-col v-if="item.make_id">
                            <div
                              class="primary--text"
                              v-html="item.make_id"
                            ></div>
                          </v-col>
                          <v-col v-if="item.model_id">
                            <div
                              class="primary--text"
                              v-html="item.model_id"
                            ></div>
                          </v-col>
                          <v-col v-if="item.trim_id">
                            <div
                              class="primary--text"
                              v-html="item.trim_id"
                            ></div>
                          </v-col>
                          <v-col v-if="item.make_year">
                            <div
                              class="primary--text"
                              v-html="item.make_year"
                            ></div>
                          </v-col>
                          <v-col v-if="item.color_id">
                            <div
                              class="primary--text"
                              v-html="item.color_id"
                            ></div>
                          </v-col>
                          <v-col v-if="item.odometer">
                            <div
                              class="primary--text odometer"
                              v-html="item.odometer"
                            ></div>
                          </v-col>
                          <v-col v-if="item.origin">
                            <div
                              class="primary--text origin"
                              v-html="item.origin"
                            ></div>
                          </v-col>
                          <v-col v-if="item.plate">
                            <div
                              class="primary--text"
                              v-html="item.plate"
                            ></div>
                          </v-col>
                          <v-col v-if="item.status">
                            <div
                              class="primary--text"
                              v-html="item.status"
                            ></div>
                          </v-col>
                          <v-col v-if="item.inspection">
                            <div
                              class="primary--text"
                              v-html="item.inspection"
                            ></div>
                          </v-col>
                          <v-col v-if="item.action_id">
                            <div
                              class="primary--text"
                              v-html="item.action_id"
                            ></div>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </template>
              </div>
            </v-card>

            <v-card>
              <template>
                <v-card-actions>
                  <v-btn color="primary" block @click="primary_button()"
                    >Ok</v-btn
                  >
                </v-card-actions>
              </template>
            </v-card>
          </v-dialog>
        </div>

        <p v-if="this.status != 'Pending inspection' && this.status != 'In Inspection'">
          {{  ('Total Selected') }}: {{ selectedItemCount }}
        </p>


        <p v-if="this.status == 'Pending inspection' || this.status == 'In Inspection'">
          {{  ('Selected For Assignement') }}: {{ selectedInspectorCount }}
        </p>
        


        <div class="flex col page-actions justify-content-end" style="display:-webkit-inline-box; margin-bottom: -2rem">
          <v-btn
            v-if="this.status == 'Pending inspection' || this.status == 'In Inspection' "
            class="btn btn-primary btn-sm"
            height="30"
            min-width="60"
            @click="showModal = true"
            color="primary"
          >
            {{  nameofassign }}

          </v-btn>
          <v-checkbox
            v-if="this.status == 'Pending inspection' || this.status == 'In Inspection' "
            v-model="selectAll"
            label="Select All"
            @change="selectAllItems($event,value)"
            style="margin-top: 0px"
          ></v-checkbox>
        </div>
        <v-dialog v-model="showModal" max-width="500">
          <v-card style="max-height: 600px; min-height: 300px">
            <v-card-title
              style="font-size: 16px; color: #555; height: 16;font-weight: normal; text-align: center;"
            >
              Assign To
              <v-spacer></v-spacer>
              <v-btn icon @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col>
                  <div
                    style="display: flex; align-items: center; margin-top: 30px"
                  >
                    <v-label>
                      <span>Assign To</span>
                      <span style="color: red">*</span>
                    </v-label>
                    <v-select
                      v-model="assignee_data"
                      :items="users"
                      item-text="name"
                      item-value="id"
                      hide-details
                      @change="ponChange($event)"
                      style="background-color: #ccc"
                    >
                    </v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-btn
                    @click="handleOk"
                    color="white"
                    text-color="black"
                    style="border: 1px solid #000"
                  >
                    Ok
                    <v-icon right>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <template @mouseover="style = 'cursor: pointer'">
          <v-data-table
            :headers="items_headers"
            :items="auction_items"
            fixed-header
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :item-key="row_id"
            class="elevation-1"
            :items-per-page="itemsPerPage"
            hide-default-footer
            height="70vh"
          >
            <template v-slot:item.name="{ item }">
              <v-simple-checkbox
                :value="item.inspect"
                @click="InsertSelected(item)"
                v-model="item.inspect"
                :disabled="is_disabled"
                
              ></v-simple-checkbox>
            </template>

            <template v-if="show_buttons" v-slot:item.duplicateRow="{ item }">
              <v-btn
                color="blue"
                @click="duplicateRow(item)"
                icon
                :disabled="is_disabled"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>

            <template v-if="show_buttons && this.Has_Access('Purchase Opportunity','delete')" v-slot:item.deleteRow="{ item }">
              <v-btn
                color="red"
                @click="deleteRow(item)"
                icon
                :disabled="is_disabled"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template
              v-if="this.status == 'In Inspection' || ((this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' ||this.status == 'Price Proposal(Pending Approval)' ) && this.$store.state.USER_ROLES.includes(this.editable_by))"
              v-slot:item.view="{ item }"
            >
              <v-btn
                v-if="item.po_status == 'Done'"
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.view"
                :items="view"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @click="print_po(item.name)"
                >{{  ('view') }}
              </v-btn>
            </template>

            <template
              v-if="this.status == 'Pending inspection' || this.status == 'In Inspection'"
              v-slot:item.assignee_user="{ item }"
            >
              <v-simple-checkbox
                v-model="item.inspector"
                :items="assignee_user"
                @click="InsertSelected(item,$event,)"
                :value="item.name"
                :disabled="item.is_val_inspection"
              ></v-simple-checkbox>
            </template>

            <template v-slot:item.make_id="{ item, index }">
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.make_id"
                :items="car_make"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :key="index"
                @change="car_make_validation($event, item, index)"
                :disabled="item.ismake"
              >
              </v-select>
            </template>
            <template v-slot:item.model_id="{ item, index }">
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                :class="['row_id_' + item.id]"
                v-model="item.model_id"
                :items="item.car_model_list"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="car_model_validation($event, item, index)"
                :key="index"
                :disabled="item.ismodel"
                ref="mySelect"
              >
              </v-select>
            </template>
            <template v-slot:item.trim_id="{ item, index  }">
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.trim_id"
                :items="item.car_trim_list"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.istrim"
              >
              </v-select>
            </template>
            <!-- <template
              v-slot:item.number_of_cabins="{ item }"
              v-if="this.status != 'Price Proposal'"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.number_of_cabins"
                :items="number_of_cabins"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.iscabins"
              >
              </v-text-field>
            </template> -->
            <template
              v-slot:item.fuel="{ item }"
            >
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.fuel"
                :items="fuel"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.isfuel"


              >
              </v-select>
            </template>
            <template
              v-slot:item.drivetrain="{ item }"
            >
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.drivetrain"
                :items="drivetrain"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.isdrivetrain"

              >
              </v-select>
            </template>
















            <template
              v-slot:item.engine_size="{ item }"
              
            >
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.engine_size"
                :items="engine_sizes"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.isengine"
              >
              </v-select>
            </template>
            <template
              v-slot:item.cylinders_number="{ item }"
           
            >
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.cylinders_number"
                :items="cylinder_number"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.iscylinders"
              >
              </v-select>
            </template>
            <template v-slot:item.make_year="{ item }">
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.make_year"
                :items="make_year"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.ismake_year"
              >
              </v-text-field>
            </template>
            <template v-slot:item.color_id="{ item }">
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.color_id"
                :items="car_color"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.iscolore"
              >
              </v-select>
            </template>
            <template v-slot:item.odometer="{ item }">
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.odometer"
                :items="odometer"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.isodometer"
                @change="ponChange($event)"
              >
              </v-text-field>
            </template>

            <template v-slot:item.user="{ item }">
              <v-select
                v-if="user_assignee"
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.user"
                :items="users"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.is_val_inspection"
                @change="changeuser_assignee($event,item)"
              >
              </v-select>
            </template>

            <template
              v-if="is_cars_validate"
              v-slot:item.purchase_request="{ item }"
            >
              <v-select
                style="width: 280px"
                dense
                background-color="white"
                v-model="item.purchase_request"
                :items="purchase_request_list"
                outlined
                item-text="name"
                item-value="name"
                hide-details
                @change="ponChange($event)"
              >
              </v-select>
            </template>

            <template
              v-if="is_cars_validate"
              v-slot:item.odometer_eye="{ item }"
            >
              <!-- add eye icon -->
              <v-btn
                style="width: 100px"
                dense
                background-color="white"
                v-model="item.odometer_eye"
                :items="odometer_eye"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.is_odometer"
                @click="show_photo(item.name, 'odometer')"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>

            <template v-if="is_cars_validate" v-slot:item.plate_eye="{ item }">
              <!-- add eye icon -->
              <v-btn
                style="width: 100px"
                dense
                background-color="white"
                v-model="item.plate_eye"
                :items="plate_eye"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.is_plate"
                @click="show_photo(item.name, 'plate')"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>

            <template v-if="is_cars_validate" v-slot:item.vin_eye="{ item }">
              <!-- add eye icon -->
              <v-btn
                style="width: 100px"
                dense
                background-color="white"
                v-model="item.vin_eye"
                :items="vin_eye"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.is_vin"
                @click="show_photo(item.name, 'vin')"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>

            <template v-if="is_cars_validate" v-slot:item.vin="{ item }">
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.vin"
                :items="item.vin"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.is_vin"
                @change="ponChange($event)"
              >
              </v-text-field>
            </template>

            
            <template v-slot:item.plate="{ item, index }" v-if="!['Price Proposal', 'Price Estimation', 'Review Price Estimation' ,'Price Proposal(Pending Approval)', 'In Inspection'].includes(this.status)">
              <v-row class="mt-2 plate_column" v-if="item.plate">
                <v-col>
                  <v-text-field v-model="item.plate_number"
                    @change="validatePlateNumber(item.id + '-part4', $event)" maxlength="4" placeholder="1234"
                    @input="handleInput(item.id + '-part4', $event)" :ref="item.id + '-input4'" outlined
                    dense style="width: 80px" :disabled="is_disabled"></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" v-model="item.plate_text_3" :items="car_plate_letter"
                    item-text="name" item-value="id" @input="handleInput(item.id + '-part3', $event)"
                    @change="ponChange($event)" :ref="item.id + '-input3'" :disabled="is_disabled" outlined
                    dense>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" v-model="item.plate_text_2" :items="car_plate_letter"
                    item-text="name" item-value="id" @input="handleInput(item.id + '-part2', $event)"
                    @change="ponChange($event)" :ref="item.id + '-input2'" :disabled="is_disabled" outlined
                    dense>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" v-model="item.plate_text_1" :items="car_plate_letter"
                    item-text="name" item-value="id" @input="handleInput(item.id + '-part1', $event)"
                    @change="ponChange($event)" :ref="item.id + '-input1'" :disabled="is_disabled" outlined
                    dense>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-2 plate_column" v-else>
                <v-col>
                  <v-text-field @change="validatePlateNumber(item.id + '-part4', $event)" maxlength="4"
                    @input="handleInput(item.id + '-part4', $event)" placeholder="1234"
                    :ref="item.id + '-input4'" outlined dense style="width: 80px"
                    :disabled="is_disabled"></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" :items="car_plate_letter" item-text="name" item-value="id"
                    @input="handleInput(item.id + '-part3', $event)" :ref="item.id + '-input3'"
                    :disabled="is_disabled" outlined dense>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" :items="car_plate_letter" item-text="name" item-value="id"
                    @input="handleInput(item.id + '-part2', $event)" :ref="item.id + '-input2'"
                    :disabled="is_disabled" outlined dense>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete style="width: 80px;" :items="car_plate_letter" item-text="name" item-value="id"
                    @input="handleInput(item.id + '-part1', $event)" :ref="item.id + '-input1'"
                    :disabled="is_disabled" outlined dense>
                  </v-autocomplete>
                </v-col>


              </v-row>

            </template>
            <template v-slot:item.plate="{item, index}" v-else>
              <v-text-field style="width: 150px;" dense background-color="white" v-model="item.plate" :items="plate"
                outlined item-text="name" item-value="id" hide-details :disabled="true"
                @change="ponChange($event)">
              </v-text-field>
            </template>

            <template
              v-if="this.status == 'In Inspection'"
              v-slot:item.po_status="{ item }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.po_status"
                :items="po_status"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="true"
                @change="ponChange($event)"
              >
              </v-text-field>
            </template>
            <template
              v-if="(this.status == 'In Inspection' || this.status == 'Price Proposal'
                || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.recommendation="{ item }"
            >
              <v-select
                style="width: 270px"
                dense
                background-color="white"
                v-model="item.recommendation"
                :disabled="item.isrecommendation"
                :items="recommendation_list"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :key="index"
              >
              </v-select>
            </template>

            <template
              v-slot:item.action_list="{ item, index }"
              v-if="(this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
            >
              <v-select
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.action"
                :disabled="item.isaction_list_status"
                :items="item.action_list"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event), change_action(item, $event)"
              >
              </v-select>
            </template>
            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.price_proposed="{ item, index }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.proposed_price"
                :items="price_proposed"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :key="index"
                :disabled="item.isproposed_price"
                :ref="index.toString() + '_price_proposed'"
                @change="ponChange($event),change_proposed_price(item, $event)"
              >
              </v-text-field>
            </template>
            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.total_proposed_price_without_tax="{ item, index }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.total_proposed_price_without_tax"
                :items="price_proposed"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :disabled="item.istotal_proposed_price_without_tax"
                :key="index"
                :ref="index.toString() + '_price_proposed'"
                @change="ponChange($event)"
              >
              </v-text-field>
            </template>
            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.added_cost="{ item }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.added_costs"
                :items="added_cost"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :key="index"
                @change="ponChange($event)"
                :disabled="item.isadded_costs"
              >
              </v-text-field>
            </template>
            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.profit="{ item }"
            >
              <v-text-field
                style="width: 50px"
                dense
                background-color="white"
                v-model="item.profit_"
                :items="profit"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :key="index"
                @change="ponChange($event)"
                :disabled="item.isprofit_"
              >
              </v-text-field>
            </template>
            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)')"
              v-slot:item.estimated_selling="{ item, index }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.estimated_selling"
                :items="estimated_selling"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                :key="index"
                :disabled="item.isestimated_selling"
                :ref="index.toString() + '_isestimated_selling'"
                @change="ponChange($event), change_estimated_selling(item, $event)"
              >
              </v-text-field>
            </template>

            <template
              v-if="(this.status == 'Price Proposal' || this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)') "
              v-slot:item.estimated_cost="{ item }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.estimated_cost"
                :items="estimated_cost"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.isestimated_cost"
              >
              </v-text-field>
            </template>
            <template
              v-if="this.status == 'In Inspection'"
              v-slot:item.estimated_costs="{ item }"
            >
              <v-text-field
                style="width: 120px"
                dense
                background-color="white"
                v-model="item.estimated_costs"
                :items="estimated_cost"
                outlined
                item-text="name"
                item-value="id"
                hide-details
                @change="ponChange($event)"
                :disabled="item.isestimated_costs"
              >
              </v-text-field>
            </template>

            <template
              style="width: 250px"
              v-if="this.status == 'Price Proposal'"
              v-slot:item.result="{ item }"
            >
              <div style="display: flex; justify-content: space-between">
                <v-btn
                  icon
                  :color="item.result == 1 ? 'green' : 'lightblue'"
                  @click="thumb_up($event, item)"
                  :disabled="item.isresult"
                >
                  <v-icon
                    >{{ item.result == 1 ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon
                  >
                </v-btn>

                <v-btn
                  icon
                  :color="item.result == 2 ? 'red' : 'lightblue'"
                  @click="thumb_down($event, item)"
                  :disabled="item.isresult"
                >
                  <v-icon
                    >{{ item.result == 2 ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon
                  >
                </v-btn>
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row class="mt-2">
                  <v-col v-if="item.name">
                    <div class="primary--text" v-html="item.duplicateRow"></div>
                  </v-col>
                  <v-col v-if="item.name">
                    <div class="primary--text" v-html="item.deleteRow"></div>
                  </v-col>

                  <v-col v-if="item.name">
                    <div class="primary--text" v-html="item.name"></div>
                  </v-col>

                  <v-col v-if="item.make_id">
                    <div class="primary--text" v-html="item.make_id"></div>
                  </v-col>
                  <v-col v-if="item.model_id">
                    <div class="primary--text" v-html="item.model_id"></div>
                  </v-col>
                  <v-col v-if="item.trim_id">
                    <div class="primary--text" v-html="item.trim_id"></div>
                  </v-col>
                  <!-- <v-col v-if="item.number_of_cabins">
                    <div
                      class="primary--text"
                      v-html="item.number_of_cabins"
                    ></div>
                  </v-col> -->
                  <v-col v-if="item.fuel">
                    <div class="primary--text" v-html="item.fuel"></div>
                  </v-col>
                  <v-col v-if="item.drivetrain">
                    <div class="primary--text" v-html="item.drivetrain"></div>
                  </v-col>
                  <v-col v-if="item.engine_size">
                    <div class="primary--text" v-html="item.engine_size"></div>
                  </v-col>
                  <v-col v-if="item.cylinders_number">
                    <div
                      class="primary--text"
                      v-html="item.cylinders_number"
                    ></div>
                  </v-col>
                  <v-col v-if="item.make_year">
                    <div class="primary--text" v-html="item.make_year"></div>
                  </v-col>
                  <v-col v-if="item.color_id">
                    <div class="primary--text" v-html="item.color_id"></div>
                  </v-col>
                  <v-col v-if="item.odometer">
                    <div class="primary--text" v-html="item.odometer"></div>
                  </v-col>

                  <v-col v-if="item.plate">
                    <div class="primary--text" v-html="item.plate"></div>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
          <br />
          <div class="form-column col-sm-1">
            <v-btn
              style="text-align: center; width: 35px; height: 35px"
              block
              rounded="xl"
              @click="addRow"
              :disabled="addRowBtnDisabled"
              color="primary"
            >
              +
            </v-btn>
          </div>

          <template v-if="Assignee_enable">
            <div class="main">
              <div class="notAssigned">
                <p id="one">Not Assigned =</p>
                <span class="not_assign">{{ notAssignedCount }}</span>
              </div>
              <div class="flex">
                <div v-for="(item, index) in this.users" :key="index">
                  <template v-if="isIdInAuctionItems(item.id)">
                    <p>
                      {{ item.name }} =
                      <span
                        class="assigned"
                        >{{ userWiseAssignedCount(item.id) }}</span
                      >
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ToastMsg from './ToastMsg.vue';

export default {
  props: ['editable_by', 'auction_id', 'status', 'auction_type', 'cars_information_unavailable',
    'auction_date', 'dialog', 'selected_item', 'auction_options', 'header_name', 'clickCount', 'event_workflow',
    'item_workflow', 'redOdometerIndexes', 'redMakeYearIndexes','addRowBtnDisabled','carsInformationUnavailable'],
  watch: {


    status:async function () {
      // const vm = this;
      const auctionId = this.auction_id;
      const apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_workflow_actions?auction_id=${auctionId}&sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`;
      let get_workflow_actions = await fetch(apiUrl)
        
      let data  = await get_workflow_actions.json()
      console.log("Data111 ===<>", data)

      let workflowActions = data.message[0];
      let editableBy = data.message[1];

      this.workflow_actions = workflowActions;
      this.editable_by = editableBy;



      if (this.status == 'Price Proposal') {

        this.is_disabled = false;
        
      }
      if (this.status != 'Draft' && this.status != 'Pending inspection' && this.status != 'In Inspection') {
        this.is_disabled = true;
        
        
        if (this.items_headers.findIndex(({ value }) => value === 'user') == -1) {
          this.items_headers.push({ text:  ('Assignee'), value: 'user', align: 'start',sortable: true });
        }
      }
      if (this.status != 'Pending inspection' && this.status != 'In Inspection') {
        this.user_assignee = false;
        this.Assignee_enable = false;
      }

      if (this.status == 'Price Proposal') {
        this.is_disabled = true;
        
        this.Assignee_enable = true;



        this.items_headers.push(

          { text:  ('Recommendation'), value: 'recommendation', align: 'start',sortable: true },
          { text:  ('Proposed Price Before Tax'), value: 'total_proposed_price_without_tax', align: 'start',sortable: true },
          { text:  ('Price Proposed'), value: 'price_proposed', align: 'start',sortable: true},
          { text:  ('Added Cost'), value: 'added_cost', align: 'start',sortable: true },
          { text:  ('Estimated Costs'), value: 'estimated_costs', align: 'start',sortable: true },
          { text:  ('Estimated Selling'), value: 'estimated_selling', align: 'start',sortable: true },
          { text:  ('Profit'), value: 'profit', align: 'start',sortable: true },
          { text:  ('Result'), value: 'result', align: 'start' ,sortable: true},

          { text:  ('View'), value: 'view', align: 'start',sortable: true},
        )
      }
      if (this.status == 'Price Estimation' || this.status == 'Review Price Estimation' || this.status == 'Price Proposal(Pending Approval)') {
        this.is_disabled = true;
        
        
        this.Assignee_enable = true;



        this.items_headers.push(
          { text:  ('Recommendation'), value: 'recommendation', align: 'start',sortable: true},
          { text:  ('Action'), value: 'action_list', align: 'start' ,sortable: true},
          { text:  ('Proposed Price Before Tax'), value: 'total_proposed_price_without_tax', align: 'start' ,sortable: true},
          { text:  ('Proposed Price'), value: 'price_proposed', align: 'start',sortable: true },
          { text:  ('Added Cost'), value: 'added_cost', align: 'start',sortable: true },
          { text:  ('Estimated Cost'), value: 'estimated_cost', align: 'start',sortable: true },
          { text:  ('Estimated Selling'), value: 'estimated_selling', align: 'start' ,sortable: true},
          { text:  ('Profit'), value: 'profit', align: 'start',sortable: true },
          { text:  ('View'), value: 'view', align: 'start' ,sortable: true},

        )
      }

      // if (this.status != 'Price Proposal') {
      //   let trim_index = this.items_headers.findIndex(({ value }) => value === 'trim_id');
      //   let items_headers = this.items_headers;
      //   // items_headers.splice(trim_index + 1, 0, { text:  ('Cabins No.'), value: 'number_of_cabins', align: 'start', });
      //   items_headers.splice(trim_index + 2, 0, { text:  ('Engine Size'), value: 'engine_size', align: 'start' });
      //   items_headers.splice(trim_index + 3, 0, { text:  ('Cylinder No.'), value: 'cylinders_number', align: 'start' });

      // }



      if (this.status != 'Draft') {
        this.show_buttons = false;
        if (this.items_headers.findIndex(({ value }) => value === 'name') != -1) {
          this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'name'), 1);
        }
      }

      console.log("Role ===<>", this.$store.state.USER_ROLES.includes(this.editable_by))
      console.log("This ===<>", this)
      console.log("Edit By ===<>", this.editable_by)
      console.log("cars_information_unavailable By ===<>", this.cars_information_unavailable)
      console.log("Status ===<>", this.status)

      if (this.status == 'Pending inspection') {
        this.user_assignee = true;
        this.is_readonly = false


        for (const item of this.auction_items) {

          Vue.set(item, "inspector", false);
          item.inspector = false
          if (item.s_item == true) {
            Vue.set(item, "s_item", false);
            Vue.set(item, "s_item", true);
          }
          else {
            Vue.set(item, "s_item", true);
            Vue.set(item, "s_item", false);
          }
        }

        
        if (this.$store.state.USER_ROLES.includes(this.editable_by)) {
          
            this.is_disabled = true;
            
            this.Assignee_enable = true;
            if (this.items_headers.findIndex(({ value }) => value === 'user') == -1) {
              this.items_headers.push({ text:  ('Assignee'), value: 'user', align: 'start',sortable: true });
              this.items_headers.splice(2, 0, { text:  ('Assignee User'), value: 'assignee_user', align: 'start',sortable: true });
            }

          
        }
        else {
          setTimeout(() => {
            this.is_disabled = true;
            
            this.Assignee_enable = false;
            if (this.items_headers.findIndex(({ value }) => value === 'user') != -1) {
              this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'user'), 1);
            }
          }, 300);
        }
      }

      else {

        setTimeout(() => {
          if (this.items_headers.findIndex(({ value }) => value === 'user') != -1) {
            this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'user'), 1);
          }
        }, 300);
      }


      if (this.status == 'In Inspection') {
        this.is_disabled = false;
        this.is_readonly = false;
        this.user_assignee = true;
        this.Assignee_enable = false;
        this.items_headers.splice(2, 0, { text:  ('Assignee User'), value: 'assignee_user', align: 'start',sortable: true });
        this.items_headers.push({ text:  ('Status'), value: 'po_status', align: 'start',sortable: true },
          { text:  ('Recommendation'), value: 'recommendation', align: 'start',sortable: true },
          { text:  ('Estimated Costs'), value: 'estimated_costs', align: 'start' ,sortable: true},
          { text:  ('View'), value: 'view', align: 'start',sortable: true },
          
        );


        if (this.$store.state.USER_ROLES.includes(this.editable_by)) {

          setTimeout(() => {
            this.Assignee_enable = true;
            if (this.items_headers.findIndex(({ value }) => value === 'user') == -1) {
              this.items_headers.push({ text:  ('Assignee'), value: 'user', align: 'start' ,sortable: true});
            }

          }, 300);
        }
      }

          if (this.status == 'Cars Info Validation') {
        if (this.$store.state.USER_ROLES.includes(this.editable_by)) {
          this.is_disabled = false;
          
        }
        else {
          this.is_disabled = true;
          
        }
        this.is_cars_validate = true;
        this.items_headers.push(
          { text:  ('VIN'), value: 'vin', align: 'start' ,sortable: true},
          { text:  ('Purchase Request'), value: 'purchase_request', align: 'start' ,sortable: true}

        );
        if (this.items_headers.findIndex(({ value }) => value === 'odometer') != -1) {
          this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'odometer') + 1, 0, { text:  (''), value: 'odometer_eye', align: 'start' ,sortable: true});
        }
        if (this.items_headers.findIndex(({ value }) => value === 'plate') != -1) {
          this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'plate') + 1, 0, { text:  (''), value: 'plate_eye', align: 'start' ,sortable: true});
        }
        if (this.items_headers.findIndex(({ value }) => value === 'vin') != -1) {
          this.items_headers.splice(this.items_headers.findIndex(({ value }) => value === 'vin') + 1, 0, { text:  (''), value: 'vin_eye', align: 'start' ,sortable: true});
        }
      }
      setTimeout(() => {
        this.addRowBtnDisabled = this.is_disabled;
      }, 500);
    },
  },
  data: () => ({
    pending_action: 0,
    is_val_inspection: true,
    rejected: 0,
    approved: 0,
    total_proposed_price: 0,
    total_proposed_price_without_tax: 0,
    is_readonly: true,
    user_assignee: false,
    is_cars_validate: false,
    redMakeYearIndexes: [],
    auction_items_data: [],
    redOdometerIndexes: [],
    assignee_data: null,
    showModal: false,
    assigneeDict: {},
    purchases: [],
    loading: false,
    pos_profile: '',
    auction_items: [],
    allItems: [],
    item: [],
    itemsPerPage: 1000,
    expanded: [],
    make_id: 0,
    car_make: [],
    car_model: [],
    car_model_list: [],
    recommendation_list: [],
    action_list: [],
    purchase_request_list: [],
    assignee_user: false,
    car_trim_list: [],
    is_trim: false,
    isengine: false,
    car_trim: [],
    forceRerender: false,
    car_plate_letter: [],
    selected_item: [],
    singleExpand: false,
    dialog: false,
    align: "text-left",
    isHidden: false,
    is_disabled: false,
    addRowBtnDisabled: false,
    carsInformationUnavailable: false,
    refreshComponent: true,
    Assignee_enable: false,
    show_buttons: true,
    result: 0,
    ispo_status: false,
    isrecommendation: true,
    istotal_proposed_price_without_tax:false,
    isaction_list: false,
    isaction_list_status: false,
    isproposed_price: false,
    isadded_costs: false,
    isprofit_: false,
    isestimated_selling: false,
    isestimated_cost: false,
    isestimated_costs: false,
    iscabins: false,
    isengine: false,
    iscylinders: false,
    ismake_year: false,
    iscolore: false,
    isodometer: false,
    ismake: false,
    ismodel: false,
    istrim: false,
    isresult: false,
    isfuel: false,
    isdrivetrain: false,
    isresultall: true,
    inspector:false,
    selectedInspectorCount: 0,


   items_headers: [
    { text:  (''), value: 'duplicateRow', align: 'start' ,sortable: true},
      { text:  (''), value: 'deleteRow', align: 'start' ,sortable: true},
      { text:  ('Inspect'), value: 'name', align: 'start' ,sortable: true},
      { text:  ('Make'), value: 'make_id', align: 'start',sortable: true },
      { text:  ('Model'), value: 'model_id', align: 'start',sortable: true },
      { text:  ('Trim'), value: 'trim_id', align: 'start' ,sortable: true},
      { text:  ('Fuel'), value: 'fuel', align: 'start' ,sortable: true},
      { text:  ('Engine Size'), value: 'engine_size', align: 'start' ,sortable: true},
      { text:  ('Cylinder No.'), value: 'cylinders_number', align: 'start' ,sortable: true},
      { text:  ('Drivetrain'), value: 'drivetrain', align: 'start' ,sortable: true},
      { text:  ('Make Year'), value: 'make_year', align: 'start' ,sortable: true},
      { text:  ('Color'), value: 'color_id', align: 'start' ,sortable: true},
      { text:  ('Odometer'), value: 'odometer', align: 'start' ,sortable: true},
      { text:  ('Plate'), value: 'plate', align: 'start' ,sortable: true},
    ],

    purchase_status: [{ id: '1', name: 'In an active auction' }, { id: '2', name: 'Vendor Rejected' },
    { id: '3', name: 'Procurement Rejected' }, { id: '4', name: 'Purchased before' }
      , { id: '5', name: 'year exceed the allowed limit' }, { id: '6', name: 'Odometer exceed the allowed limit' }, { id: '7', name: 'Odometer and Year exceed the allowed limit' }
    ],
    dialog_headers: [
      { text:  (''), value: 'name', align: 'start' },
      { text:  ('Make'), value: 'make_id', align: 'start' },
      { text:  ('Model'), value: 'model_id', align: 'start' },
      { text:  ('Trim'), value: 'trim_id', align: 'start' },
      { text:  ('Make Year'), value: 'make_year', align: 'start' },
      { text:  ('Color'), value: 'color_id', align: 'start' },
      { text:  ('Odometer'), value: 'odometer', align: 'start' },
      { text:  ('origin'), value: 'origin', align: 'start' },
      { text:  ('Plate'), value: 'plate', align: 'start' },
      { text:  ('Status'), value: 'status', align: 'start' },
      { text:  ('Inspection'), value: 'inspection', align: 'start', width: '150px' },
      { text:  ('Action'), value: 'action_id', align: 'start' },
    ],
    changedKeys: false,
    auction_options: []
  }),
  components: {
    ToastMsg,
  },
  mounted() {
    if (this.auction_id === "new-auction") {

      let trim_index = this.items_headers.findIndex(({ value }) => value === 'trim_id');
      let items_headers = this.items_headers;
      // items_headers.splice(trim_index + 1, 0, { text:  ('Cabins No.'), value: 'number_of_cabins', align: 'start', });
      // items_headers.splice(trim_index + 2, 0, { text:  ('Engine Size'), value: 'engine_size', align: 'start' });
      // items_headers.splice(trim_index + 3, 0, { text:  ('Cylinder No.'), value: 'cylinders_number', align: 'start' });

    }
  },
  methods: {
    change_action(item, event) {
      if(item.action== "Approved"){

      Vue.set(item, "isestimated_selling", false)
      }
      else{
        item.proposed_price = 0
        Vue.set(item, "isestimated_selling", true)
        Vue.set(item, "isproposed_price", false)
        Vue.set(item, "estimated_selling", 0)
        this.change_estimated_selling(item)

      }


    },
    export_auction() {
      // https://uat-erp.ta7akum.com/api/method/frappe.utils.print_format.download_pdf?doctype=Cars%20Inspection&name=INS-2024-579708&format=Inspection%20Report&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=en-US
        let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.download_template.download_auction_template?sid=${this.$store.state.SID}&auction_id=${this.auction_id}`;
        window.open(apiUrl, '_blank').focus();
},
    

    change_proposed_price(item, index) {



      Vue.set(item, "isproposed_price", false)
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_estimated_selling?sid=${this.$store.state.SID}`;
      fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => response.json())
            .then(data => {
              if (data.message) {
                let profit = data.message;
                item.estimated_cost = parseFloat(index) + item.added_costs;
                item.profit_ = ((item.estimated_selling - item.estimated_cost) / item.estimated_selling) * 100;
                item.total_proposed_price_without_tax = (parseFloat(item.proposed_price) / 1.15).toFixed(3); 
                console.log("Profit", item.profit_)

              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
    },


    change_estimated_selling(item, index) {


      if (item.estimated_selling !="0"){

        Vue.set(item, "isproposed_price", false)
      }
      else{
        Vue.set(item, "isproposed_price", true)

      }

      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_estimated_selling?sid=${this.$store.state.SID}`;

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            let profit = data.message;
            item.profit_ = profit;
            item.estimated_cost = item.estimated_selling - (parseFloat(profit) / 100) * item.estimated_selling;
            if (item.action == "Approved") {
              item.proposed_price = parseFloat(item.estimated_cost) - parseFloat(item.added_costs);
              item.total_proposed_price_without_tax =  (parseFloat(item.proposed_price) / 1.15).toFixed(3);
            }

          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

    },



    show_photo(name, photo_type) {
      if (name) {
        let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_photo`;

        let args = {
          purchase_oppurtunity_id: name,
          photo_type: photo_type,
          sid: this.$store.state.SID
        };

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
          },
          body: JSON.stringify(args),
        })
          .then(response => response.json())
          .then(data => {

            if (data.message) {
              const url = this.$store.state.DATA_DOMAIN;
              let full_url
              if(data.message.includes("syarah_document_management") || !data.message.includes("https://s3")){
                full_url = url + data.message
              }
              else {
                full_url = data.message
              }
              window.open(full_url, '_blank').focus();
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

      }
    },
    print_inspection(name){
      if(name){
        console.log("name", name)
      let url = `${this.$store.state.DATA_DOMAIN}/api/method/frappe.utils.print_format.download_pdf?doctype=Cars%20Inspection&name=${name}&format=Inspection%20Report&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=${document.documentElement.getAttribute('lang')}&sid=${this.$store.state.SID}`;
      window.open(url, '_blank').focus();
      }

    },
    print_po(name) {
      if (name) {
        let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/resource/Cars Inspection?fields=["name"]&filters=[["reference_id", "=", "${name}"]]&sid=${this.$store.state.SID}`;

        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (data.data.length > 0) {
              let carInspectionName = data.data[0].name;
              if(carInspectionName) {
                let url = `${this.$store.state.DATA_DOMAIN}/api/method/frappe.utils.print_format.download_pdf?doctype=Cars%20Inspection&name=${carInspectionName}&format=Inspection%20Report&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=ar&sid=${this.$store.state.SID}`;
                window.open(url, '_blank').focus();
              }
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
    },
    close_dialog() {
      this.dialog = false;

      this.reload()
    },
    reload() {
      this.$parent.reload()
    },


    getStatusName(statusId) {
      const status = this.purchase_status.find(item => item.id === statusId);
      return status ? status.name : 'Unknown';
    },

    openPage(inspectionId) {

      if (inspectionId) {

        const currentUrl = window.location.href;

        const urlParts = currentUrl.split('/').slice(0, 3);
        const baseUrl = urlParts.join('/');
        
        const pageUrl = `${this.$store.state.DATA_DOMAIN}app/cars-inspection/${inspectionId}`;
        window.open(pageUrl);
      }

    },
    closeModal() {
      this.showModal = false;
    },
    handleOk() {
      this.selectedInspectorCount = 0;
      let selected_user = this.assignee_data
      console.log(selected_user)
      for (const item of this.auction_items) { 
        console.log("item",item)

        if (item.inspector) {
        //   if (item.po_status != "Pending"){
        //   const errorMsg = '<h4>All Fields are required You can only re-assign inspections that are pending</h4>';
        //       let toast_comp = <ToastMsg msg={errorMsg} />
        //       this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
        //       return;
        // }
        // else
        // {

          Vue.set(item, "user", selected_user);
          item.user = selected_user
          if (item.s_item == true) {
            Vue.set(item, "s_item", false);
            Vue.set(item, "s_item", true);
          }
          else {
            Vue.set(item, "s_item", true);
            Vue.set(item, "s_item", false);
          }
        // }

        }
        
        Vue.set(item, "inspector", false);
        item.inspector = false
        if (item.s_item == true) {
          Vue.set(item, "s_item", false);
          Vue.set(item, "s_item", true);
        }
        else {
          Vue.set(item, "s_item", true);
          Vue.set(item, "s_item", false);
        }
      }
      
      
      this.closeModal();
      return;
      for (const item of this.auction_items) {
        this.purchases.push({ "purchase_id": item.name });
      }
      this.$set(this.assigneeDict, 'auction_id', this.auction_id);
      this.$set(this.assigneeDict, 'purchases', this.purchases);

      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.create_cars_inspection`;

      let args = {
        assigneeDict: this.assigneeDict,
        'sid': this.$store.state.SID
      };

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
        },
        body: JSON.stringify(args),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

    },
    getStatusName(statusId) {
      const status = this.purchase_status.find(item => item.id === statusId);
      return status ? status.name : 'Unknown';
    },
    userWiseAssignedCount(id) {
      let count = 0;
      for (const item of this.auction_items) {
        if (item.user == id) {
          count += 1;
        }
      }
      return count;
    },
    isIdInAuctionItems(id) {
      return this.auction_items.some(item => item.user === id);
    },


    openDialog() {

    },
    onSecondClick() {
    },
    onThirdClick() {

    },
     async onSubsequentClick() {
      this.showLoadingIndicator("Processing Data","#0089FF")
      let processAuctionItems = async () => {
      const vm = this;
      console.log("select item dealog",this.selected_item)
      console.log("auction item dealog",this.auction_items)
      let isItemSelected 
      let selected_items
      let req
      let res
      let action_list_vaildation = []
        for (let auction_item of this.auction_items) {
          console.log("auction_item 111111",auction_item)
          // console.log("auction_item 111111",auction_item.name)
          isItemSelected = this.selected_item.some(selectedItem => selectedItem.name === auction_item.name);

        if (isItemSelected) {
          console.log(`Auction item is selected! ${auction_item.name}`);

          selected_items = this.selected_item.find(item => item.name === auction_item.name);
          if (selected_items.auction_value == "Remove") {
                      this.dialog = false;
                      console.log("remove",selected_items.name)
                      action_list_vaildation.push({name:auction_item.name,action:"Remove",is_inspected:"0"})
                      // let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.deleteitem`;
                      // req = await fetch(apiUrl, {
                      //   method: 'POST',
                      //   headers: {
                      //     'Content-Type': 'application/json',
                      //     'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                      //   },
                      //   body: JSON.stringify({
                      //     selectedItem: auction_item.name,
                      //     sid: this.$store.state.SID
                      //   }),
                      // })
                      // res = await req.json()

                }
                else if (selected_items.auction_value == "Do not inspect") {
                      if (auction_item.name == selected_items.name) {
                        auction_item.s_item = false
                      }
                      console.log("Do not inspect",selected_items.name)
                      action_list_vaildation.push({name:auction_item.name,action:"Do not inspect",is_inspected:"0"})
                    // let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_opportunity_status`;

                    // let args = {
                    //   opportunity: selected_items.name,
                    //   is_inspected: "0",
                    //   sid: this.$store.state.SID
                    // };

                    // req = await fetch(apiUrl, {
                    //   method: 'POST',
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //     'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                    //   },
                    //   body: JSON.stringify(args),
                    // })
                    // res = await req.json()

                  }
                  else {
                    console.log("Procced",selected_items.name)
                    action_list_vaildation.push({name:auction_item.name,action:"Procced",is_inspected:"1"})
                      // let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_opportunity_status`;

                      // let args = {
                      //   opportunity: selected_items.name,
                      //   is_inspected: "1",
                      //   sid: this.$store.state.SID
                      // };

                      // req = await fetch(apiUrl, {
                      //   method: 'POST',
                      //   headers: {
                      //     'Content-Type': 'application/json',
                      //     'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

                      //   },
                      //   body: JSON.stringify(args),
                      // })
                      // res = await req.json()

                    }
                
        } else {
          console.log(`Auction item is not selected! ${auction_item.name}`);
          console.log("auction_item.inspect",auction_item.inspect)
          action_list_vaildation.push({name:auction_item.name,action:"without_valedation",is_inspected:auction_item.inspect})
          // let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_opportunity_status?sid=${this.$store.state.SID}&opportunity=${auction_item.name}&is_inspected=${auction_item.inspect}`;

          // // let args = {
          // //   opportunity: auction_item.name,
          // //   is_inspected: auction_item.inspect,
          // //   sid: this.$store.state.SID
          // // };

          // req = await fetch(apiUrl, {
          //   method: 'GET',
          //   headers: {
          //     'Content-Type': 'application/json',

          //   },
          //   // body: JSON.stringify(args),
          // })
          // res = await req.json()
          this.reload()
            
        }

      }
      let auction_cars_dict_val = {
        "cars": action_list_vaildation
      }
      console.log("auction_cars_dict_val",auction_cars_dict_val)
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_opportunity_status_valedation`;

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
            },
            body: JSON.stringify({
                sid: this.$store.state.SID,
                auction_cars_dict_val: auction_cars_dict_val
            })
        });

        const data = await response.json();
        console.log("data draft to pending",data)
    }
    
    let processWorkflow = async () => {
      let workflow_actions = this.$parent.workflow_actions;
      console.log("workflow_actions",workflow_actions[0].action)
      console.log("workflow_actions",workflow_actions)
      // if (workflow_actions[0].action == 'Send to inspection') {
        console.log("workflow_actionsss",workflow_actions)
        workflow_actions.forEach(async (element) => {
          this.dialog = false;
          this.$parent.workflow_state = element.next_state;

          let req_workflow = await fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

            },
            body: JSON.stringify({
              doctype: "Auction",
              docname: this.auction_id,
              // field:"workflow_state",
              // value: element.next_state,
              action: "Send to inspection",
              sid: this.$store.state.SID

            }),
          })
         
         
          let res_workflow = await req_workflow.json()
          .then(updateData => {
                              if (updateData.status && updateData.status.message === "Error in Update Workflow") {
                                    console.log("Error in Update Workflow:", updateData);
                                    const errorMsg = '<h4>' + updateData.message.replace("ValidationError", "") + '</h4>';
                                    let toast_comp = <ToastMsg msg={errorMsg} />
                                    this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })              
                                    return;
                                }
                              // vm.get_workflow_actions();
                              this.reload()
                            })
          console.log("res_workflow",res_workflow)
          // this.reload()
        });
      // }
    }
    await processAuctionItems();
    await processWorkflow();
    this.dialog = false;
    this.reload()
    this.removeLoadingIndicator()
    },
    showLoadingIndicator(message,color) {
    const loadingIndicator = document.createElement('div');
    loadingIndicator.innerHTML = `<div class="spinner-border" role="status" style='color:${color}'></div><span style="margin-left:1rem;"><b style='font-size:14px;color:${color}'>${message}</b></span>`;
    loadingIndicator.style.position = 'fixed';
    loadingIndicator.style.top = '0';
    loadingIndicator.style.left = '0';
    loadingIndicator.style.width = '100%';
    loadingIndicator.style.height = '100%';
    loadingIndicator.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
    loadingIndicator.style.display = 'flex';
    loadingIndicator.style.justifyContent = 'center';
    loadingIndicator.style.alignItems = 'center';
    loadingIndicator.style.zIndex = '9999';
    loadingIndicator.classList.add('loading-indicator');
    document.body.appendChild(loadingIndicator);
},
removeLoadingIndicator() {
    const loadingIndicator = document.querySelector('.loading-indicator');
    if (loadingIndicator) {
        loadingIndicator.remove();
        this.$store.commit('incrementRefreshKey');
        this.$store.commit('incrementRefreshKey');
    }
},

    primary_button() {



      let is_selected = true;
      this.selected_item.forEach(function (value, key) {


        if (!value.auction_value) {
          is_selected = false;
          return false;
        }
      });
      const vm = this;
      this.selected_all_item = this.selected_item
      this.clickCount++;
      if (!is_selected) {
        console.error('Please select action for all cars');
        let errorMsg = '<h4>Please select action for all cars</h4>';
        let toast_comp = <ToastMsg msg={errorMsg} />
        this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
        return false;
      }
      switch (this.clickCount) {
        case 1:
          this.dialog = false;
          this.onSubsequentClick();
          break;
        default:

          break;
      }

    },
    InsertSelected(item,event) {
      this.$parent.onChange(event)
   
      if (item.inspector) {
        
            this.selectedInspectorCount++; // Increment count when checkbox is checked
        } else {
            this.selectedInspectorCount--; // Decrement count when checkbox is unchecked
        }
    },
    SendToInspect() {
      const cars = []
      let index = 0
      for (const item of this.auction_items) {
        if (this.auction_type == "Auction" || (this.auction_type == "Wholesale" && this.cars_information_unavailable == true)) {
          let string = ""
          if (item.make_id == null) {
            string = string + "Make, "
          }
          if (item.model_id == null) {
            string = string + "Model, "
          }
          if (item.trim_id == null) {
            string = string + "Trim, "
          }
          if (item.make_year == null) {
            string = string + "Make Year, "
          }
          if (item.plate == null) {
            string = string + "Plate, "
          }
          if (this.auction_date == null) {
            string = string + "Auction Date, "
          }
          if (string != "") {
            let errorMsg = `<h4>Please fill the following fields: ${string}</h4>`
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return false;
          }
        }
        const plate = item.plate;
        let plate1 = this.$refs[index.toString() + '-input1'].value;
        let plate2 = this.$refs[index.toString() + '-input2'].value;
        let plate3 = this.$refs[index.toString() + '-input3'].value;
        let plate_number = this.$refs[index.toString() + '-input4'].value;
        let plate_display = plate1 + ' ' + plate2 + ' ' + plate3 + ' - ' + plate_number;
        index++;
        if (plate) {
          let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.check_plate_number`;

          let args = {
            plate: plate,
            auction: this.auction_id,
            'sid': this.$store.state.SID
          };

          fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
            },
            body: JSON.stringify(args),
          })
            .then(response => response.json())
            .then(data => {
              console.log("data in inspect", data)
              if (data.message) {
                if (data.message == false) {
                  let errorMsg = '<h4>Plate Number is not valid</h4>';
                  let toast_comp = <ToastMsg msg={errorMsg} />
                  this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                  return false;
                }
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });

        }
        const carsitem = {
          name: item.inspect,
          make_id: item.make,
          model_id: item.model,
          trim_id: item.trim,
          make_year: item.make_year,
          color_id: item.color,
          odometer: item.odometer,
          plate: item.plate,
        }
        if (item.s_item == true) {
          cars.push(carsitem);
        }
      }
      this.selected_item = cars;

    },
    addRow() {
      this.$parent.onChange()
      const newRow = {
        name: null,
        make_id: null,
        model_id: null,
        trim_id: null,
        // number_of_cabins: '',
        fuel: '',
        drivetrain: '',
        engine_size: '',
        cylinders_number: '',
        make_year: '',
        color_id: null,
        odometer: '',
        plate: '',
        Assignee: '',
        s_item: true,
        inspect: true,
        plate_text_1: '',
        plate_text_2: '',
        plate_text_3: '',
        plate_number: '',
        id: this.auction_items.length + 1,
      };

      newRow.index = this.auction_items.length;
      this.auction_items.push(newRow);

    },

    car_make_validation(event, item, index) {
      this.$parent.onChange(event)


      this.make_id = event


      this.get_car_model(event, item, index)

    },


    car_model_validation(event, item, index) {
      this.$parent.onChange(event)
      const selectElement = this.$refs.mySelect;



      this.$set(item, 'trim_id', null);
      this.$set(item, 'trim', null);



      this.get_car_trim(event, item, index)

    },
    ponChange(event) {



      this.$parent.onChange(event)
    },

    changeuser_assignee(event,item){
      // if (item.po_status != "Pending"){
      //   const errorMsg = '<h4>You can only re-assign inspections that are pending</h4>';
      //       let toast_comp = <ToastMsg msg={errorMsg} />
      //       this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
      //       this.$store.commit('incrementRefreshKey');
      //       return;
      // }
      // else
      // {
        this.$parent.onChange(event)
      // }
    },

    thumb_up(event, item) {

      this.$parent.onChange(event)
      this.$set(item, 'result', 1);



      if (item.s_item == true) {
        Vue.set(item, "s_item", false);
        Vue.set(item, "s_item", true);
      }
      else {
        Vue.set(item, "s_item", true);
        Vue.set(item, "s_item", false);
      }

    },

    thumb_down(event, item) {
      this.$parent.onChange(event)
      this.$set(item, 'result', 2);



      if (item.s_item == true) {
        Vue.set(item, "s_item", false);
        Vue.set(item, "s_item", true);
      }
      else {
        Vue.set(item, "s_item", true);
        Vue.set(item, "s_item", false);
      }


    },
    setAllThumbsUp() {
      this.$parent.onChange(event)
      this.auction_items.forEach(item => {
        this.$set(item, 'result', 1);

        if (item.s_item == true) {
          Vue.set(item, "s_item", false);
          Vue.set(item, "s_item", true);
        }
        else {
          Vue.set(item, "s_item", true);
          Vue.set(item, "s_item", false);
        }
      });


    },

    setAllThumbsDown() {
      this.$parent.onChange(event)
      this.auction_items.forEach(item => {
        this.$set(item, 'result', 2);

        if (item.s_item == true) {
          Vue.set(item, "s_item", false);
          Vue.set(item, "s_item", true);
        }
        else {
          Vue.set(item, "s_item", true);
          Vue.set(item, "s_item", false);
        }


      });
    },

    selectAllItems(event,value){
      // this.get_items()
   
      this.selectedInspectorCount= 0;
      this.$parent.onChange(event)
      // this.auction_items.forEach(item => {
        for (const item of this.auction_items) {

        console.log("Auction items count:", this.auction_items.length);
        console.log("item:", item);
        console.log("item.is_val_inspection:", item.is_val_inspection);
        console.log("item.au_status:", item.au_status);

        if ((item.is_val_inspection == false && item.au_status == "In Inspection") || item.au_status ==  "Pending inspection" || item.au_status ==  "Draft" ){
      
          
          console.log("item", item)
          console.log("item", item.inspector)
            // this.selectedInspectorCount= this.auction_items.length;
        if (event == true){
          this.$set(item, 'inspector', 1);
          this.selectedInspectorCount++;
          
        }
        else {
          this.$set(item, 'inspector', 0);
          this.selectedInspectorCount= 0;
        }
        

        if (item.s_item == true) {
          Vue.set(item, "s_item", false);
          Vue.set(item, "s_item", true);
        }
        else {
          Vue.set(item, "s_item", true);
          Vue.set(item, "s_item", false);
        }
          }
        
  
      };
    },




    testModel(event, item) {



    },
    updatecars() {
      const cars = [];

      for (const item of this.auction_items) {
        const carsitem = {
          name: item.name,
          make_id: item.make_id,
          model_id: item.model_id,
          trim_id: item.trim_id,
          // number_of_cabins: item.number_of_cabins,
          fuel: item.fuel,
          drivetrain: item.drivetrain,
          engine_size: item.engine_size,
          cylinders_number: item.cylinders_number,
          make_year: item.make_year,
          color_id: item.color_id,
          odometer: item.odometer,
          plate: item.plate,


        }

        cars.push(carsitem);
      }


      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.updatecars`;

      let args = {
        cars: cars,
        'sid': this.$store.state.SID
      };

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
        },
        body: JSON.stringify(args),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

    },
    onChange(event) {
      this.changedKeys = true;
    },
    ponChange(event) {

      this.$parent.onChange(event)
    },
    get_items() {
      if (!this.auction_id) {
        
        return;
      }
      const langAttribute = document.documentElement.getAttribute('lang');
      if (langAttribute === 'ar') {
        this.align = "text-left";
        
      } else {
        this.align = "text-right";
      }
      const vm = this;
      this.loading = true;
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_auction_cars?auction_id=${this.auction_id}&sid=${this.$store.state.SID}&with_all_data=1&lang=${document.documentElement.getAttribute('lang')}`;
      
      let args = {
        auction_id: vm.auction_id,
        with_all_data: 1,
        lang: document.documentElement.getAttribute('lang')
      };
      
      console.log("this.auction_id= Before Fetching = =>", this.auction_id)
      fetch(apiUrl)
      .then(response => {
        console.log("response ===> ", response)
        return response.json()})
        
        .then(data => {
          if (data.message) {
            console.log("Test Message ==>", data.message)
            
            vm.auction_items = data.message[0];
            vm.car_make = data.message[1];
            vm.car_model = data.message[2];
            vm.car_trim = data.message[3];
            vm.car_color = data.message[4];
            vm.engine_sizes = data.message[5];
            vm.fuel= data.message[14];
            vm.drivetrain = data.message[15];
            vm.cylinder_number = data.message[6];
            vm.users = data.message[7];
            vm.car_model_list = [];
            vm.car_trim_list = [];
            vm.recommendation_list = data.message[10];
            vm.purchase_request_list = data.message[11];
            vm.pending_action = data.message[12].Pending_Actions;
            vm.rejected = data.message[12].Rejected_Actions;
            vm.approved = data.message[12].Approved_Actions;
            vm.total_proposed_price= data.message[12].Total_Proposed_Price;
            vm.total_proposed_price_without_tax= data.message[12].total_proposed_price_without_tax;
  
            vm.$emit('childEvent', data.message[0]);



            let id = 0;
            for (const item of vm.auction_items) {

              let photo_cars = data.message[13].filter(photo => photo.name == item.name);
              console.log("photo_cars", photo_cars)

              let photo_cars_vin = photo_cars.filter(photo_car => photo_car.photo_type == "VIN");
              let photo_cars_Plate = photo_cars.filter(photo_car => photo_car.photo_type == "Plate");
              let photo_cars_Odometer= photo_cars.filter(photo_car => photo_car.photo_type == "Odometer");
              console.log("photo_cars_vin", photo_cars_vin)
              console.log("photo_cars_Plate", photo_cars_Plate)
              console.log("photo_cars_Odometer", photo_cars_Odometer)

              if (photo_cars_vin.length > 0) {
                Vue.set(item, "is_vin", false);
              }
              else {
                Vue.set(item, "is_vin", true);
              }
              
              if (photo_cars_Plate.length > 0) {
                Vue.set(item, "is_plate", false);
              }
              else {
                Vue.set(item, "is_plate", true);
              }

              if (photo_cars_Odometer.length > 0) {
                Vue.set(item, "is_odometer", false);
              }
              else {
                Vue.set(item, "is_odometer", true);
              }


              item.id = id;
              item.car_model_list = data.message[2];
              item.car_trim_list = data.message[3];
              item.action_list = [{ id: '', name: "" }, { id: 'Approved', name: 'Approved' }, { id: 'Rejected', name: 'Rejected' }];
              item.is_trim = false;
              item.isrecommendation = true;
              item.isaction_list = true;
              item.isaction_list_status = true;
              item.isadded_costs = true;
              item.istotal_proposed_price_without_tax = true;
              item.isprofit_ = true;
              item.isresult = true;
              item.isresultall = true;
              item.isestimated_selling = true;
              item.isproposed_price = true;
              item.isestimated_cost = true;
              item.isestimated_costs = true;
              item.iscabins = true;
              item.isengine = true;
              item.iscylinders = true;
              item.ismake_year = true;
              item.iscolore = true;
              item.isodometer = true;
              item.ismake = true;
              item.ismodel = true;
              item.istrim = true;
              item.is_val_inspection = true;
              item.isfuel = true;
              item.isdrivetrain = true;

              if((item.workflow_state == "In Inspection"  && item.po_status == "Pending" ) || item.workflow_state == "Pending inspection"){
               item.is_val_inspection = false;
              console.log("is_val_inspection", vm.is_val_inspection)

                
              }
              else{
                item.is_val_inspection = true;
                console.log("is_val_inspection", vm.is_val_inspection)
              }

              if (item.workflow_state == "Won") {
                item.result = 1;
              } else if (item.workflow_state == "Lost") {
                item.result = 2;
              } else {
                item.result = 0;
              }
              console.log("item", item)
              console.log("data.message[2]", data.message[2])
              console.log("item.make_id", item.make_id)
              let makeid= item.make_id;
              let filteredData_model = data.message[2].filter(item => item.car_make === makeid);
              console.log("filteredData_model", filteredData_model)
              Vue.set(item, "car_model_list", filteredData_model);
              // fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_model?make=${item.make_id}&lang=${this.$store.state.LANGUAGE}&sid=${this.$store.state.SID}`, {
              //   method: 'GET',
              //   headers: {
              //     'Content-Type': 'application/json',

              //   }
              // })
              //   .then(response => response.json())
              //   .then(carModelData => {
              //     if (carModelData.message) {
              //       Vue.set(item, "car_model_list", carModelData.message);
              //       if (item.s_item == true) {
              //         Vue.set(item, "s_item", false);
              //         Vue.set(item, "s_item", true);
              //       } else {
              //         Vue.set(item, "s_item", true);
              //         Vue.set(item, "s_item", false);
              //       }
              //       vm.loading = false;
                    
              //     }
              //   });
              console.log("data.message[3]", data.message[3])
              console.log("item.model_id", item.model_id)
              let modelid= item.model_id;
              let filteredData_trim = data.message[3].filter(item => item.car_model === modelid);
              console.log("filteredData_trim", filteredData_trim)
              Vue.set(item, "car_trim_list", filteredData_trim);
              if (item.car_trim_list && item.car_trim_list.length > 0) {
                  Vue.set(item, "is_trim", true);
                } else {
                  Vue.set(item, "is_trim", false);
                }

                if (item.s_item == true) {
                  Vue.set(item, "s_item", false);
                  Vue.set(item, "s_item", true);
                } else {
                  Vue.set(item, "s_item", true);
                  Vue.set(item, "s_item", false);
                }
                vm.loading = false;

              // fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_trim?model=${item.model_id}&lang=${this.$store.state.LANGUAGE}&sid=${this.$store.state.SID}`, {
              //   method: 'GET',
              //   headers: {
              //     'Content-Type': 'application/json',

              //   },

              // })
              //   .then(response => response.json())
              //   .then(carTrimData => {
              //     if (carTrimData.message) {
              //       Vue.set(item, "car_trim_list", carTrimData.message);

              //       if (item.car_trim_list && item.car_trim_list.length > 0) {
              //         Vue.set(item, "is_trim", true);
              //       } else {
              //         Vue.set(item, "is_trim", false);
              //       }

              //       if (item.s_item == true) {
              //         Vue.set(item, "s_item", false);
              //         Vue.set(item, "s_item", true);
              //       } else {
              //         Vue.set(item, "s_item", true);
              //         Vue.set(item, "s_item", false);
              //       }
              //       vm.loading = false;
                    
              //     }
              //   });

              id++;
            }
            vm.loading = false;
            
            
          }
          
          vm.validation_workflow();
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

        fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_plate_letter?sid=${this.$store.state.SID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            console.log("data get_car_plate_letter",data)
            if (data.message) {
              vm.car_plate_letter = data.message;
              vm.loading = false;
              
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

    },

    validation_workflow() {


        for (const item of this.auction_items) {

          if ((item.au_status == 'Price Estimation' || item.au_status == 'Review Price Estimation' || item.au_status == 'Price Proposal(Pending Approval)') && this.$store.state.USER_ROLES.includes(this.editable_by)) {


            Vue.set(item, "ismake", false);
              item.ismodel = false
              item.istrim = false
              item.ismake_year = false
              item.iscolore = false
              item.isodometer = false
              // item.iscabins = false
              item.isengine = false
              item.iscylinders = false
              item.isfuel = false
              item.isdrivetrain = false
              


            item.isaction_list = false
            item.isaction_list_status = false

            if (item.action == "Approved") {
              item.isestimated_selling = false
              item.isproposed_price = false
            }
            else {
              item.isestimated_selling = true
              item.isproposed_price = true
            }

          }
          else if ((item.au_status == "Price Proposal") && (this.$store.state.USER_ROLES.includes(this.editable_by))) {
          
            Vue.set(item, "ismake", false);
              item.ismodel = false
              item.istrim = false
              item.ismake_year = false
              item.iscolore = false
              item.isodometer = false
              // item.iscabins = false
              item.isengine = false
              item.iscylinders = false
              item.isfuel = false
              item.isdrivetrain = false
              

              item.isestimated_selling = false
              item.isproposed_price = false
              item.isresult = false
              this.isresultall= false

              if (item.s_item == true) {
                Vue.set(item, "s_item", false);
                Vue.set(item, "s_item", true);
              }
              else {
                Vue.set(item, "s_item", true);
                Vue.set(item, "s_item", false);
              }
    



          if (item.s_item == true) {
            Vue.set(item, "s_item", false);
            Vue.set(item, "s_item", true);
          }
          else {
            Vue.set(item, "s_item", true);
            Vue.set(item, "s_item", false);
          }
          }

          else if (item.au_status == "Draft" || item.au_status == "Pending Team Leader") {


            this.is_disabled = false;
            if (this.carsInformationUnavailable) {
                this.addRowBtnDisabled = true;
              }
              else {
                this.addRowBtnDisabled = false;
              }

            Vue.set(item, "ismake", false);
            item.ismodel = false
            item.istrim = false
            item.ismake_year = false
            item.iscolore = false
            item.isodometer = false
            // item.iscabins = false
            item.isengine = false
            item.iscylinders = false
            item.isfuel = false
            item.isdrivetrain = false

          }
          else if (item.au_status == "Cars Info Validation" && this.$store.state.USER_ROLES.includes(this.editable_by)) {
            item.ismake = false
            item.ismodel = false
            item.istrim = false
            item.ismake_year = false
            item.iscolore = false
            item.isodometer = false
            // item.iscabins = false
            item.isengine = false
            item.iscylinders = false
            item.isfuel = false
            item.isdrivetrain = false
          }
          else if (item.au_status == "In Inspection") {
            if (item.po_status == 'Done') {
              console.log("saleeee",item.po_status )
              console.log("saleeee",item )
              item.isrecommendation = false
              item.isestimated_selling = false
              item.isestimated_costs = false
            }
          }

          if (item.s_item == true) {
            Vue.set(item, "s_item", false);
            Vue.set(item, "s_item", true);
          }
          else {
            Vue.set(item, "s_item", true);
            Vue.set(item, "s_item", false);
          }
        }
 
    },
    duplicateRow(selectedItem) {
      this.$parent.onChange()

      const duplicatedItem = { ...selectedItem };
      duplicatedItem.name = null;
      duplicatedItem.id = this.auction_items.length + 1
      duplicatedItem.index = this.auction_items.length;
      duplicatedItem.plate = ""
      duplicatedItem.plate_number = ""
      duplicatedItem.plate_text_1 = ""
      duplicatedItem.plate_text_2 = ""
      duplicatedItem.plate_text_3 = ""
      duplicatedItem.odometer = 0
      this.auction_items.push(duplicatedItem);

    },

    deleteRow(selectedItem) {
      var index = this.auction_items.findIndex(item => item.id === selectedItem.id);

      if (index !== -1) {
        this.auction_items.splice(index, 1);

        this.$parent.onChange();

        this.updateItemIndices();
      } else {
        console.error('Item not found');
      }
    },

    updateItemIndices() {
      this.auction_items.forEach((item, index) => {
        item.index = index;
      });
    },

    get_car_model(car_make, item, index) {
      const vm = this;
      this.loading = true;
      let car_model = [];

      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_model?sid=${this.$store.state.SID}&make=${car_make}&lang=${this.$store.state.LANGUAGE}`;

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            const cls = "row_id_" + item.id;

            
            

            
            
            

            Vue.set(item, "car_model_list", data.message);
            if (item.s_item == true) {
              Vue.set(item, "s_item", false);
              Vue.set(item, "s_item", true);
            } else {
              Vue.set(item, "s_item", true);
              Vue.set(item, "s_item", false);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });



    },
    Has_Access( doctype, permissionType) {
      const doctypePermissions = this.$store.state.DOCTYPE_PERMISSIONS
    if (doctypePermissions.hasOwnProperty(doctype)) {
            return doctypePermissions[doctype].some(permission => permission[permissionType] === 1);
        }
        return false;
    },
    get_car_trim(car_model, item, index) {
      const vm = this;
      this.loading = true;
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_car_trim?sid=${this.$store.state.SID}&model=${car_model}&lang=${this.$store.state.LANGUAGE}`;

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            
            Vue.set(item, "car_trim_list", data.message);
            if (item.car_trim_list && item.car_trim_list.length > 0) {
              Vue.set(item, "is_trim", true);
            } else {
              Vue.set(item, "is_trim", false);
            }

            if (item.s_item == true) {
              Vue.set(item, "s_item", false);
              Vue.set(item, "s_item", true);
            } else {
              Vue.set(item, "s_item", true);
              Vue.set(item, "s_item", false);
            }

            vm.loading = false;
            vm.forceRerender = !vm.forceRerender;
            
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

    },

    handleInput(fieldName, event) {
      const letterPattern = /^[أ-يa-zA-Z]+$/;




      if (letterPattern.test(event)) {
        if (fieldName.includes("part1")) {
          if (event.length === 1) {
            let index = fieldName.split("-")[0];
            let input = this.$refs[index + "-input2"];
          }
        } else if (fieldName.includes("part2")) {
          if (event.length === 1) {
            let index = fieldName.split("-")[0];
            let input = this.$refs[index + "-input1"];
            input.focus();
          }
        } else if (fieldName.includes("part3")) {
          if (event.length === 1) {
            let index = fieldName.split("-")[0];
            let input = this.$refs[index + "-input2"];
            input.focus();
          }
        }

      } else {
        if (fieldName.includes("part4")) {
          if (event.length === 4) {
            let index = fieldName.split("-")[0];
            let input = this.$refs[index + "-input3"];
            input.focus();
          }
        }
        else {
          event = "";
        }
      }

    },
    validatePlateNumber(fieldName, event) {
      const vm = this;
      const digitsPattern = /^\d+$/;
      this.$parent.onChange(event)

      let Validate_Number = !isNaN(parseFloat(event)) && !isNaN(event - 0)
      if (!Validate_Number) {
        let errorMsg = '<h4>The Plate Number Should Be Number</h4>'
        let toast_comp = <ToastMsg msg={errorMsg} />
        this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
        return;
      }
      else if (event.length < 4) {

        let Validate_Number = !isNaN(parseFloat(event)) && !isNaN(event - 0)
        if (!Validate_Number) {
          let errorMsg = '<h4>The Plate Number Should Be Number</h4>'
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          return;
        }
        else if (event.length < 1) { 
          let errorMsg = '<h4>The Plate Number Should Fill</h4>'
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          return;
        }

        if (event.length === 4) {
          let index = fieldName.split("-")[0];
          let input = this.$refs[index + "-input3"];
        }
        vm.ponChange(event)

      }
    },
  },
  created() {
    this.showLoadingIndicator("Processing Data","#0089FF")
    this.get_items();
    const loadingIndicator = document.querySelector('.loading-indicator');
    if (loadingIndicator) {
        loadingIndicator.remove();
    }
    this.loading = true;
  },
  computed: {
    filteredHeaders() {

      return this.isHidden
        ? this.dialog_headers.filter((header) => header.value !== 'origin')
        : this.dialog_headers;
    },

    selectedItemCount() {
      const selectedItems = this.auction_items.filter(item => item.s_item);


      return selectedItems.length;
    },
    nameofassign(){
      if (this.status  == "In Inspection") {
        return "Re-Assign"
      }
      else {
        return "Assign"
      }
    },
    notAssignedCount() {
      const notAssignedItems = this.auction_items.filter(item => item.user);
      return this.auction_items.length - notAssignedItems.length;
    },
    get_auction_cars() {
      return this.auction_items
    },

  }
};
function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000);
  return `${timestamp}_${random}`;
}
</script>
<style scoped>
.border_line_bottom {
  border-bottom: 1px solid lightgray;
}

.disable-events {
  pointer-events: none;
}

#title {
  color: rgb(47, 25, 105);
  font-weight: bold;
}

.flex {
  display: contents;
}

.notAssigned {
  display: flex;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.red-text {
  color: red;
}

.not_assign {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  text-align: center;
}

.assigned {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: green;
  color: white;
  text-align: center;
}
.plate_column{
  display: flex;
  flex-wrap: nowrap;
}
table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
</style>